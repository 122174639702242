import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocationModel } from '../models/location.model';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http:HttpClient) { }
  
  getLocation(clientID,token,reference){  
    let locationID = reference.substring(reference.lastIndexOf('/')+1)
    return new Promise<LocationModel>((resolve,reject)=>{    
      const headers = new HttpHeaders()
      .set("Epic-Client-ID", clientID) 
      .set('Authorization', "Bearer "+ token) 
      .set('content-type', 'application/json')    
 
      this.http.get(environment.appochard_baseurl+`R4/Location/${locationID}`,
       { headers: headers }).subscribe((res:any)=>{ 
          resolve(res);
      })   
    });
  }
}
