import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TermsAndConditionPage } from 'src/app/modules/terms-and-condition/terms-and-condition.page';

@Component({
  selector: 'app-footer-customize',
  templateUrl: './footer-customize.component.html',
  styleUrls: ['./footer-customize.component.scss'],
})
export class FooterCustomizeComponent implements OnInit {

  constructor(private modalCtrl:ModalController) { }

  ngOnInit() { 
  }

  async onTermsAndCondition(){ 
    const modal = await this.modalCtrl.create({
      component: TermsAndConditionPage,
      cssClass:"termsandcondition"
    });
    await modal.present();
  }
}
