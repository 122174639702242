export const environment = {
    appVersion: require('../../package.json').version + '-uat', 
    production: false,
    environment: "uat",
    debug: true,  
    appochard_baseurl:"https://vendorservices.epic.com/interconnect-amcurprd-oauth/api/FHIR/",
    baseURL:"https://uat-api.epic.vv.my-compass.io/",
    patientLaunchClientID:"05a60ef5-4ea8-4915-a68f-bd72666740b4",
    providerLaunchClientID:"5ea455d4-aca8-4311-a1e6-ad20e9feea96",
    chime:"https://34ga56ctce.execute-api.us-east-1.amazonaws.com/Prod/",  
    surveyLink:"https://master.d1cdbq4fuoiuv9.amplifyapp.com/"  
  };
  
  