import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.page.html',
  styleUrls: ['./terms-and-condition.page.scss'],
})
export class TermsAndConditionPage implements OnInit { 
   @ViewChild('printSection', { read: ElementRef }) printSection: ElementRef;
 
 
   
  constructor( private modalCtrl:ModalController) { }

  ngOnInit() { 
  }

  
  onBack(){ 
    // if(history.state.navigationId > 1 ) { 
    //   this.navCtrl.back();
    // }
    // else {  
    //   this.navCtrl.navigateBack("app");
    // } 
  }
   customPrint() { 
    (<any> document.getElementById("export_btn")).style.display = "none";
     const printContent = this.printSection.nativeElement;
     const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
     WindowPrt.document.write(printContent.innerHTML);  
     const interval = setInterval(
       () => {
         if (document.readyState === 'complete') {
           WindowPrt.document.close();
           WindowPrt.focus();
           WindowPrt.print();
           WindowPrt.close();
           (<any> document.getElementById("export_btn")).style.display = "table";
           clearInterval(interval);
         }
       },
       200);
   }

   onCloseModal(){
    this.modalCtrl.dismiss();
   }
}
