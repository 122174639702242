import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AlertController, LoadingController } from '@ionic/angular';
import { CustomHttpsService } from 'src/app/core/utils/custom-https.service';
import { TenantModel } from 'src/app/models/tenant.model';
import * as FHIR from "fhirclient";
import { environment } from 'src/environments/environment';
import { TenantService } from 'src/app/services/tenant.service';
import { TextUtilService } from 'src/app/core/utils/text.util.service';
import { AppoinmentSearchService } from 'src/app/services/appoinment.search.service';
import { PatientModel } from 'src/app/models/patient.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppointmentFhirModel } from 'src/app/models/appointmentfhir.model';
import { PatientService } from 'src/app/services/patient.service';
import { AppointmentModel } from 'src/app/models/appointment.model';
import { Practitioner } from 'src/app/models/practitioner.model';
import { ProvidersService } from 'src/app/services/providers.service';
const {v4 :uuidv4} = require('uuid');

@Component({
  selector: 'app-emr-ready',
  templateUrl: './emr-ready.page.html',
  styleUrls: ['./emr-ready.page.scss'],
})
export class EmrReadyPage implements OnInit {
  env= environment;
  clientID = environment.patientLaunchClientID;
  appointments:AppointmentFhirModel[]|any = []; 
  chimeIframe:SafeResourceUrl;  tenantModel:TenantModel={};patient:PatientModel; appointmentFhirModel:AppointmentFhirModel;
  patientDeviceID = "";MeetingID = "";  isDebug=false;code= "";isProduction=false; isDesktop = false;  aptNotes=[];  devAppId = ""; 
  token=""; apptId = ""; cTimers:any[]=[]; appoinmentMsgStatus = ""; serverDate:any;  appointmentAppOrchard:AppointmentModel; 
  isBothOnline = false; isMeetingRun = true; isAvailableSoon = false; isHideTimer = false; isMeetingEnd = false; timezone: string;
  practitioner:Practitioner; 
  // appointmentID = "";  
  prodAppId = ""; 
  constructor(
    public sanitizer: DomSanitizer,
    private loadingController:LoadingController,
    private tenantService:TenantService,
    private http:HttpClient,
    private patientService:PatientService,
    private providersService:ProvidersService,
    private appointmentSearch :AppoinmentSearchService,
    private alertController:AlertController,
    private cusHttp:CustomHttpsService,) { 
      
    this.chimeIframe = this.sanitizer.bypassSecurityTrustResourceUrl(environment.chime); 
    const urlParams = new URLSearchParams(window.location.search);
    this.code = urlParams.get('code');
    this.isProduction = environment.production;
    this.isDebug = environment.debug;
  }

  ngOnInit() { 
    this.tenantModel.isNlpDefaultOn = 1;
    this.tenantModel.isRecordDefaultOn = 1;
    const orgId = localStorage.getItem("patientSideOrgID");
    if(orgId){
      this.tenantModel.TenantID = orgId;
    }else{
      this.alert("No Org ID declared.");
      return;
    }
    
    this.initDevice(); 
    this.init();
  }

  
  loading :any;
  async init() {
    console.log(`init`);
    this.loading = await this.loadingController.create({ message: "Please wait ...."  });
    this.loading.present(); 
    await this.initToken();

    try{
      this.tenantModel = await this.tenantService.getWithConfig(this.tenantModel.TenantID); 
      await this.initPatient(); 
      await this.initAppointment(); 
      // await this.initPatientAppointment(); 
      await this.initPractitioner();  
  
      if(this.isProduction || !this.isDebug){
        await this.initProdChime();
        await this.initExternalConnection(); 
      }
      else{
        await this.initDevChime();
      }
      
      const isAccess = await this.initMiddleWare(); 
      console.log("asdsadas",isAccess);
      if(!isAccess){
        console.log("asdsadas====",isAccess);
        const alert = await this.alertController.create({
          message: '1 device per account only',
          backdropDismiss:false
        });
        await alert.present();
      } 
       
 
      if(this.appointmentFhirModel){  
        const ScheduledAt = this.appointmentFhirModel.start;  
        const ScheduledEndAt = this.appointmentFhirModel.end; 
        
        setInterval(()=>{ 
          const ctimer = TextUtilService.counDownTimer(ScheduledAt);  
          this.cTimers = ctimer;   
  
          const dateTimeDif = TextUtilService.getDateTimeDif(ScheduledAt);
          const dateTimeDifEnd = TextUtilService.getDateTimeDif(ScheduledEndAt); 
          if(this.isBothOnline && !this.isMeetingEnd){
            this.isMeetingRun =true;
            this.isMeetingEnd = false;
            this.isAvailableSoon = false; 
            this.isHideTimer = false; 
            // Timer of the meeting
            this.cTimers = TextUtilService.counDownTimer(this.serverDate,true);  
            this.appoinmentMsgStatus = "Appointment Has Already Begun"; 
          }else{ 
            if((dateTimeDif[0].number <= -1 && dateTimeDif[1].number <= -1 && 
              dateTimeDifEnd[2].number >= 0 && dateTimeDif[2].number <= 0) && !this.isBothOnline  ){ 
              //  Time for meeting
              this.isHideTimer = true;  
              this.isMeetingRun =true;
              this.isMeetingEnd = false;
              this.isAvailableSoon = false; 
              // Timer of the meeting
              this.cTimers = TextUtilService.counDownTimer(ScheduledAt,true);     
              this.appoinmentMsgStatus = "Your visit will start soon"; 
            }else if(dateTimeDif[0].number <= 0 && dateTimeDif[1].number <= 0 && 
              dateTimeDifEnd[2].number < 0){
              //  Meeting End 
              this.isMeetingEnd = true;
              this.isHideTimer = true;
              this.isMeetingRun = false;
              this.isAvailableSoon = false;    
              this.appoinmentMsgStatus = "This session has ended";  
            }else if(parseInt(ctimer[0].number) > 0 || parseInt(ctimer[1].number) > 0 ||
              parseInt(ctimer[2].number) > 30){ 
                // Available soon 
              this.isAvailableSoon = true;
              this.isMeetingRun = false;
              this.isHideTimer = false;
              this.isMeetingEnd = false; 
              this.appoinmentMsgStatus = "Appointment Will Start In";
            }else{
              // 30 minutes before call
                // Prepare for the meeting
              this.isMeetingRun = true;
              this.isAvailableSoon = false;
              this.isHideTimer = false;
              this.isMeetingEnd = false;
              this.appoinmentMsgStatus = "Appointment Will Start In";
            }   
          }
        },1000);  
      }else{   
        this.noAppointment();
      }       
      
      setTimeout(() => {   
        this.loading.dismiss(); 
      }, 6000); 
      
    }catch(e){ 
      this.alert(e.message);
      this.loading.dismiss();
    }  
  }  

  initPatient() {
    return new Promise<any>((resolve,reject)=>{  
      FHIR.oauth2,
      FHIR.oauth2.ready()
      .then(client => client.request(`Patient/${client.patient.id}`))
      .then((el:PatientModel)=>{ 
        console.log(el);
        this.patient= el; 
        resolve({}); 
      })
      .catch(()=>{ 
        this.onMyChartError(); 
        reject({})}
      ); 
    }) 
  }
  
  initExternalConnection() {   
    return new Promise<any>((resolve,reject)=>{   
      FHIR.oauth2.ready()
      .then(async (client) => {
        
          const tokenResponse = client.state.tokenResponse; 
          const ConferenceID= this.appointmentFhirModel.identifier[0].value;
          const ExternalID= this.patient.identifier.find((o)=>{     if(o.type){
            return o.type.text.toLowerCase() === "WPRINTERNAL".toLowerCase()}
          }).value;
          const ExternalIDType= 2;
          const VendorName= "Edera Video Visit";
          const ConnectionStatus= 1;
           
          const token = tokenResponse.access_token;
          const headers = new HttpHeaders()
          .set("Epic-Client-ID", environment.patientLaunchClientID) 
          .set('Authorization', "Bearer "+ token) 
          .set('content-type', 'application/json')   
  
          console.log(`initExternalConnection`,tokenResponse,`https://vendorservices.epic.com/interconnect-amcurprd-oauth/api/epic/2018/Telehealth/ContextLinking/SetExternalConnectionStatus?ConferenceID=${ConferenceID}&ExternalID=${ExternalID}&ExternalIDType=${ExternalIDType}&VendorName=${VendorName}&ConnectionStatus=${ConnectionStatus}`);
          await new Promise<any>((resolve)=>{   
            this.http.get(`https://vendorservices.epic.com/interconnect-amcurprd-oauth/api/epic/2018/Telehealth/ContextLinking/SetExternalConnectionStatus?ConferenceID=${ConferenceID}&ExternalID=${ExternalID}&ExternalIDType=${ExternalIDType}&VendorName=${VendorName}&ConnectionStatus=${ConnectionStatus}`,
            { headers: headers }).subscribe((res:any)=>{
                console.log(res);  
                resolve({});
            })  
          })   
          this.http.get(`${environment.appochard_baseurl}STU3/Encounter/${tokenResponse.encounter}`,
          { headers: headers }).subscribe((res:any)=>{
              console.log(res);   
          })   
          resolve({});
      }) 
      .catch(()=>{reject({})}); 
    });
  }

  async initPractitioner() {
    const reference = this.appointmentFhirModel.participant.find((el)=>{return el.actor.reference.toLowerCase().includes("practitioner");}).actor.reference;
    
    console.log(`initPractitioner`,reference);
    this.practitioner = await this.providersService.getPractitioner(this.clientID,this.token,reference);
    console.log(`initPractitioner`,this.practitioner);
  }

  // initPatientAppointment() {
  //   return new Promise<any>(async (resolve)=>{ 
  //   const patientID  = this.patient.identifier.find((o)=>{ if(o.type){return o.type.text.toLowerCase() === "EPIC".toLowerCase()}}).value;
  //   await this.patientService.getPatientAppointments(this.clientID,this.token,patientID); 
  //   const patientApts =  await this.patientService.getPatientAppointments(this.clientID,this.token,patientID);
  //   console.log("patientApts",patientApts);
  //   const currentPatientApts = patientApts.find((el)=>{return el.ContactIDs.find((er)=>{return er.ID == this.appointmentFhirModel.identifier[0].value;})  && el.VisitTypeIDs.find((er)=>{return er.ID.toLowerCase().includes("238")})  });
  //   console.log("currentPatientApts",currentPatientApts);
    
  //   const PatientID= this.patient.identifier.find((o)=>{if(o.type){ return o.type.text.toLowerCase() === "EPIC".toLowerCase()}}).value; 
  //   const MyChartAccountID = this.patient.identifier.find((o)=>{ if(o.type){return o.type.text.toLowerCase() === "WPRINTERNAL".toLowerCase()}}).value; 
  //   const appSearchs = await this.appointmentSearch.getFutureAppointments(this.clientID,this.token,PatientID,MyChartAccountID);
  //   this.appointmentAppOrchard = appSearchs.find((el)=>{return el.ContactIDs.find((er)=>{return er.ID == this.appointmentFhirModel.identifier[0].value;})});
    
  //   if(!currentPatientApts){this.noAppointment();return;}
  //   this.aptNotes = currentPatientApts.AppointmentNotes;
  //   console.log("currentPatientApts",currentPatientApts,this.appointmentAppOrchard);
  //     resolve({});
  //   })
  // }


  initAppointment() {
    return new Promise<any>(async(resolve)=>{ 
      const appsearchs = await this.appointmentSearch.get(this.clientID, this.token,this.patient.id);
      this.appointments = appsearchs;
      console.log("initAppointment",appsearchs);
      if(this.isDebug){
        this.appointmentFhirModel = appsearchs.find((el)=>{   
          const ScheduledAt = el.start;  
          const ScheduledEndAt = el.end;     
    
          const dateTimeDif = TextUtilService.getDateTimeDif(ScheduledAt);
          const dateTimeDifEnd = TextUtilService.getDateTimeDif(ScheduledEndAt); 
    
          console.log("start",dateTimeDif,ScheduledAt);
          console.log("end",dateTimeDifEnd,ScheduledEndAt);
          
          if(dateTimeDif[0].number <= 0 && dateTimeDif[1].number <= 0 && 
            dateTimeDifEnd[2].number < 0){
            //  Meeting End  
          }else{
            return true;
          } 
        })  
      }else{
        this.appointmentFhirModel = appsearchs.find((el)=>{  
          return el.id = this.apptId;
        }) 
      } 

      if(!this.appointmentFhirModel){
        this.noAppointment();
        return;
      }

      if(this.appointmentFhirModel.start){ 
        this.devAppId = this.patient.id+new Date(this.appointmentFhirModel.start).getTime() +"-"+this.tenantModel.TenantID;
      }else{
        this.noAppointment();
        return;
      } 

      this.appointments = this.appointments.map((el)=>{ 
        el.IsShow = (new Date(el.start).getTime()-new Date(this.appointmentFhirModel.start).getTime()) > 0;
        return el;
      });   
      
      console.log("initAppointment",this.appointments,this.appointmentFhirModel);
      resolve({});
    })
  }









  initProdChime() {
    return new Promise<any>((resolve)=>{  
      FHIR.oauth2.ready()
      .then(async (client) => {  
        
        const body  = {
          Provider: {
            ID:this.practitioner.id
          },
          PatientID:this.patient.id,
          TenantID:this.tenantModel.TenantID,
          AppointmentID:this.prodAppId,
          AppointmentName:this.appointmentFhirModel.serviceType[0].coding[0].display, 
          PatientName:this.patient.name[0].text, 
          ScheduledAt:this.appointmentFhirModel.start,
          ScheduledEndAt:this.appointmentFhirModel.end,
          FirstName:this.patient.name[0].given[0],
          LastName:this.patient.name[0].family,
          Gender:this.patient.gender,
          BirthDate:this.patient.birthDate
        }
        await new Promise<any>((resolve)=>{
          this.cusHttp.post(`chimeApi/createAppointment`,body)
          .subscribe((el:any)=>{resolve({})}); 
        })


        this.cusHttp.post(`chimeApi/checkMeeting?AppointmentID=${this.prodAppId}&TenantID=${this.tenantModel.TenantID}`,{})
        .subscribe((el:any)=>{ 
          this.MeetingID = el.MeetingID;  
          this.prodKeepMeAlive();
          resolve({}); 
          this.chimeIframe = this.sanitizer.bypassSecurityTrustResourceUrl(environment.chime+'?m='+el.MeetingID+"&n="+this.patient.name[0].text+"&appointmentID="+this.prodAppId+"&iam=patient"+"&isRecordDefaultOn="+this.tenantModel.isRecordDefaultOn+"&isNlpDefaultOn="+this.tenantModel.isNlpDefaultOn);    
        }); 
        
      }) 
    })
  }
  
  prodKeepMeAlive() { 
    const inter = setInterval(()=>{
      this.cusHttp.post(`chimeApi/keepmealive?AppointmentID=${this.prodAppId}&MeetingID=${this.MeetingID}&iam=patient&computerID=${this.patientDeviceID}`,{})
      .subscribe((el:any)=>{  
        console.log("keepmealive",el);
        if(el.isPatientOnline && el.isProviderOnline){
          this.isBothOnline = true;
          this.serverDate = el.serverDate;
          clearInterval(inter);
          this.prodKeepMeAlive();
        }
        if(!el.success){
          if(el.isNeedRestart){ 
            setTimeout(async () => {
              clearInterval(inter);
              await this.initProdChime(); 
            }, 3000);
          } 
        }
      });
    },this.isBothOnline ? 1000*15 : 1000*5);
  }
  
  initDevChime() {
    return new Promise<any>(async (resolve)=>{    
      const body  = {
        Provider: {
          ID:this.practitioner.id
        },
        PatientID:this.patient.id,
        TenantID:this.tenantModel.TenantID,
        AppointmentID:this.devAppId,
        AppointmentName:this.appointmentFhirModel.serviceType[0].coding[0].display, 
        PatientName:this.patient.name[0].text, 
        ScheduledAt:this.appointmentFhirModel.start,
        ScheduledEndAt:this.appointmentFhirModel.end,
        FirstName:this.patient.name[0].given[0],
        LastName:this.patient.name[0].family,
        Gender:this.patient.gender,
        BirthDate:this.patient.birthDate
      }
      await new Promise<any>((resolve)=>{
        this.cusHttp.post(`chimeApi/createAppointment`,body)
        .subscribe((el:any)=>{resolve({})}); 
      })

      this.cusHttp.post(`chimeApi/checkMeeting?AppointmentID=${this.devAppId}&TenantID=${this.tenantModel.TenantID}`,{})
      .subscribe((el:any)=>{  
        this.MeetingID = el.MeetingID;
        this.devKeepMeAlive();
        resolve({});  
        this.chimeIframe = this.sanitizer.bypassSecurityTrustResourceUrl(environment.chime+'?m='+el.MeetingID+"&n="+this.patient.name[0].text+"&appointmentID="+ this.devAppId+"&iam=patient"+"&isRecordDefaultOn="+this.tenantModel.isRecordDefaultOn+"&isNlpDefaultOn="+this.tenantModel.isNlpDefaultOn);  
      }); 

    })
  }

  devKeepMeAlive() { 
    const inter = setInterval(()=>{
      this.cusHttp.post(`chimeApi/keepmealive?AppointmentID=${this.devAppId}&MeetingID=${this.MeetingID}&iam=patient&computerID=${this.patientDeviceID}`,{})
      .subscribe((el:any)=>{ 
        console.log("keepmealive",el);
        if(el.isPatientOnline && el.isProviderOnline){
          this.isBothOnline = true;
          this.serverDate = el.serverDate;
          clearInterval(inter);
          this.devKeepMeAlive();
        }
        if(!el.success){
          if(el.isNeedRestart){ 
            setTimeout(async () => { 
              clearInterval(inter);
              await this.initDevChime();
            }, 3000);
          } 
        }
      });
    },this.isBothOnline ? 1000*15 : 1000*5);
  }

  initDevice() {
    this.patientDeviceID= localStorage.getItem("patient-uid");
    if(!this.patientDeviceID){
      this.patientDeviceID = uuidv4();
      localStorage.setItem("patient-uid",this.patientDeviceID);
    } 
  }
  
  platformInit(){
    setInterval(()=>{ 
      // if(window.innerWidth < 1026){
      if(window.innerWidth < 991){
        //is mobile
        this.isDesktop = false; 
      }else{
        //is desktop
        this.isDesktop = true; 
      }
    },1000)
  }

  initMiddleWare() {
    return new Promise<any>((resolve)=>{  
      this.cusHttp.post(`chimeApi/app-middleware?iam=patient&computerID=${this.patientDeviceID}&MeetingID=${this.MeetingID}`,{})
      .subscribe((el:any)=>{   
        resolve(el.isAccess);
      }); 
    })
  }

  async initToken() { 
    return new Promise<any>(async (resolve,reject)=>{   
      
      FHIR.oauth2.ready()
      .then(async client => {
        
        this.prodAppId = client.state.tokenResponse.appointment+ "-" +this.tenantModel.TenantID;
        this.token = client.state.tokenResponse.access_token; 

        if(!client.patient.id){ 
          try{  
            const smartkey = sessionStorage.getItem("SMART_KEY").split('"')[1];
            const clientId= environment.providerLaunchClientID;  
            var headers = new Headers(); 
            var requestOptions:any = {
              method: 'GET',
              headers: headers,
              redirect: 'follow'
            };
        
            let app = JSON.parse(sessionStorage.getItem(smartkey)); 
            if(!app.tokenResponse.access_token){
              var urlencoded = new URLSearchParams();
              urlencoded.append("code", this.code);
              urlencoded.append("grant_type", "authorization_code");
              urlencoded.append("redirect_uri", app.redirectUri);
              urlencoded.append("client_id", clientId);
      
              var requestOptions:any = {
                method: 'POST',
                headers: headers,
                body: urlencoded,
                redirect: 'follow'
              };
      
              fetch("https://vendorservices.epic.com/interconnect-amcurprd-oauth/oauth2/token", requestOptions)
              .then(response => response.json())
              .then(result => { 
                var appID = result.appointment +"-"+this.tenantModel.TenantID; 
                app = Object.assign(app,{
                  tokenResponse:result
                }) 
                sessionStorage.setItem(smartkey,JSON.stringify(app));
                this.token = app.access_token;
                this.prodAppId =  appID ;
                resolve({});
              })
              .catch(error =>{ reject({})});
            }else{
              resolve({})
            }
  
          }catch(err){
            console.log(err);
            reject({});
            const alert= await this.alertController.create({
              message: 'Session expired, please go back to HyperSpace.',
              backdropDismiss:false
            });
            await alert.present();
          }
        }else{
          resolve({});
        }
      })
    })
  }
  
  async noAppointment() {
    const alert = await this.alertController.create({
      message: 'Have no appointment scheduled.',
      backdropDismiss:false
    });
    await alert.present();  
    await this.loading.dismiss();
  }

  async onMyChartError(){ 
    const alert = await this.alertController.create({
      message: 'Session expired, please go back to MyChart.',
      backdropDismiss:false
    });
  
    await alert.present(); 
    this.loading.dismiss();
  } 

  async alert(message){ 
    console.log("alert",message);
    if(message=='' || !message){message = 'Session expired, please go back to MyChart.'}
    const alert = await this.alertController.create({
      message: message,
      backdropDismiss:false
    }); 
    await alert.present();  
  } 
}
