import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';  
import { BuildDate } from './BuildDate';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  appDetails:string = "";
  constructor(
    private datePipe:DatePipe) {
      this.appDetails = `Version: ${environment.appVersion} <br>
      Build Date : ${BuildDate.stamp,this.datePipe.transform(new Date
        (parseInt(BuildDate.stamp)).toLocaleDateString(),"EEEE, MMMM d, y")}`;
  }
}
