import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry } from '@aws-amplify/core';
import { environment } from 'src/environments/environment';
import { PatientDemographicsModel } from '../models/patient.demographics.model';
import { PatientAppointment } from '../models/patientappoinment.model';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(private http:HttpClient,
    private pipe:DatePipe) { }
  
  // getPatientAppointments(clientID,token,PatientID){ 
     
  //   return new Promise<PatientAppointment[]>((resolve,reject)=>{   
     
  //     const headers = new HttpHeaders()
  //     .set("Epic-Client-ID", clientID) 
  //     .set('Authorization', "Bearer "+ token) 
  //     .set('content-type', 'application/json')   
     
  //     let startdate = new Date();
  //     startdate.setDate(startdate.getDate()-2);
  //     let enddate = new Date();
  //     enddate.setDate(enddate.getDate()+2);
  //     const body ={
  //       "UserID": "1",
  //       "UserIDType": "External",
  //       // "StartDate": startdate.toLocaleDateString(),
  //       // "EndDate": enddate.toLocaleDateString(),
  //       "StartDate": this.pipe.transform(startdate, 'shortDate'),
  //       "EndDate":  this.pipe.transform(enddate, 'shortDate'),
  //       "PatientID":PatientID,
  //       "PatientIDType":"EPI",
  //       "IncludeAllStatuses": "true",
  //       "IncludeStatusList": [ ],
  //       "ExcludeStatusList": [ ],
  //       "IncludeOutsideAppointments": "false",
  //       "ExtraItems": [ ],
  //       "ExtraExtensions": [ ]
  //     }; 
  //     console.log('getPatientAppointments',body);
  //     this.http.post(`https://vendorservices.epic.com/interconnect-amcurprd-oauth/api/epic/2013/Scheduling/Patient/GETPATIENTAPPOINTMENTS/GetPatientAppointments`,
  //     body,{ headers: headers }).subscribe((res:any)=>{
  //         console.log('getPatientAppointments',res);  
  //         const patientAppointments :PatientAppointment[]= (<PatientAppointment[]> res.Appointments); 
  //         resolve(patientAppointments);
  //     })   
  //   });
  // }


  getPatientDemographics(clientID,token,PatientID,MyChartAccountID){ 

    return new Promise<PatientDemographicsModel[]>((resolve,reject)=>{    
      const headers = new HttpHeaders()
      .set("Epic-Client-ID", clientID) 
      .set('Authorization', "Bearer "+ token) 
      .set('content-type', 'application/json') ;
       
      this.http.get(`https://vendorservices.epic.com/interconnect-amcurprd-oauth/api/epic/2019/PatientAccess/Patient/GetPatientDemographics/Patient/Demographics?PatientID=${PatientID}&PatientIDType=EPI&MyChartAccountID=${MyChartAccountID}&MyChartAccountIDType=external`,
      { headers: headers }).subscribe((res:any)=>{  
          resolve(res);
      })   
    });

  }

  // saveCPatientDemoGraphics(PatientID, PatientDemoGraphics){  
  //   return new Promise<any>((resolve)=>{  
  //     const headers = new HttpHeaders() 
  //     .set('content-type', 'application/json') ;
      
  //     const body = {
  //       PatientID,
  //       PatientDemoGraphics
  //     }
      
  //     this.http.post(`${environment.baseURL}chimeApi/savePatientDemoGraphics`,
  //     body,{ headers: headers }).subscribe((res:any)=>{  
  //         resolve({});
  //     })   

  //   })  
  // } 
  
  saveCPatientDemoGraphics(PatientID, patientData){  
    return new Promise<any>((resolve)=>{  
      const headers = new HttpHeaders() 
      .set('content-type', 'application/json') ;
  
      let PatientDemoGraphics = {
        NationalIdentifier: this.getNationalID(patientData),
        DateOfBirth: patientData.birthDate,
        Email: this.getFindInTelecom(patientData,"email"),
        Address: {
          Street: this.getStreet(patientData),
          City: this.getCity(patientData),
          ZIP: this.getZip(patientData),
          State: this.getState(patientData),
          County: this.getCounty(patientData),
          District: this.getCounty(patientData),
          Country: this.getCountry(patientData)
        },
        LegalName:{
          FirstName : this.getFirstName(patientData),
          LastName : this.getLastName(patientData) 
        },
        MartialStatus: this.getMaritalStatus(patientData),
        Ethnicity:this.getEthnicity(patientData),
        Race:this.getRace(patientData),
        Language:this.getLanguage(patientData)

      }


      const body = {
        PatientID,
        PatientDemoGraphics
      }
      
      this.http.post(`${environment.baseURL}chimeApi/savePatientDemoGraphics`,
      body,{ headers: headers }).subscribe((res:any)=>{  
          resolve({});
      })   
    })   
  } 
  
  
  private getRace(patientData: any) { 
    try{ 
      let extension = patientData.extension.find((el)=>{return el.url.toLowerCase().includes("StructureDefinition/us-core-race".toLowerCase());})
      if(extension){
        if(extension.extension?.length){
          return extension.extension[1]?.valueString;
        }
      } 
      return"";
    }catch(e){
      return"";
    }
  } 

  private getEthnicity(patientData: any) { 
    try{ 
      let extension = patientData.extension.find((el)=>{return el.url.toLowerCase().includes("StructureDefinition/us-core-ethnicity".toLowerCase());})
      if(extension){
        if(extension.extension?.length){
          return extension.extension[1]?.valueString;
        }
      } 
      return"";
    }catch(e){
      return"";
    }
  } 

  private getMaritalStatus(patientData: any) { 
    try{ 
      if(patientData.maritalStatus){
        return patientData.maritalStatus.text;
      }
      return"";
    }catch(e){
      return"";
    }
  }

  private getLastName(patientData: any) {
    try{ 
      if(patientData.name.length){
        return patientData.name[0].family; 
      }
      return"";
    }catch(e){
      return"";
    }
  }
  
  private getFirstName(patientData: any) {
    try{ 
      if(patientData.name.length){
        let name = patientData.name[0];
        if(name.given.length){
          return name.given[0]; 
        }
      }
      return"";
    }catch(e){
      return"";
    }
  }

  private getCountry(patientData: any) {
    try{ 
      if(patientData.address.length){ 
        let address = patientData.address[0];
        if(address.country){
          return address.country;
        } 
      }
      return "";
    }catch(e){
      return"";
    }
  }
  
  private getCounty(patientData: any) {
    try{ 
      if(patientData.address.length){ 
        let address = patientData.address[0];
        if(address.district){
          return address.district;
        } 
      }
      return "";
    }catch(e){
      return"";
    }
  }
  
  private getState(patientData: any) {
    try{  
      if(patientData.address.length){ 
        let address = patientData.address[0];
        if(address.state){
          return address.state;
        } 
      }
      return "";
    }catch(e){
      return"";
    }
  }
 
  private getZip(patientData: any) {
    try{  
      if(patientData.address.length){ 
        let address = patientData.address[0];
        if(address.postalCode){
          return address.postalCode;
        } 
      }
      return "";
    }catch(e){
      return"";
    }
  }
 
  private getCity(patientData: any) { 
    try{  
      if(patientData.address.length){ 
        let address = patientData.address[0];
        if(address.city){
          return address.city;
        } 
      }
      return "";
    }catch(e){
      return"";
    }
  }

  private getStreet(patientData: any) { 
    try{  
      if(patientData.address.length){ 
        let address = patientData.address[0];
        if(address.line.length){
          return address.line.join(" ");
        } 
      }
      return "";
    }catch(e){
      return"";
    }
  }
  
  private getFindInTelecom(patientData: any,system) {
    try{  
      let tel = patientData.telecom.find((el)=>{
        return el.system ==system;
      })
      if(tel){
        return tel.value;
      }else{
        return "";
      }
    }catch(e){
      return"";
    }
  }
   
  private getLanguage(patientData: any) {
    try{  
      if(patientData.communication?.length){
        return patientData.communication[0].language.text;
      } 
      return"";
    }catch(e){
      return"";
    }
  }

  private getNationalID(patiendData: any) { 
    try{   
      let nationalID =patiendData.identifier.find((el)=>{
        if(el.extension.length){
          return !!el.extension.find((er)=>{
            return er.url.toLowerCase().includes("StructureDefinition/rendered-value".toLowerCase());
          })
        }else{
          return false;
        }
      })
      if(nationalID){
        nationalID.value;
      }else{
        return "";
      }
    }catch(e){
      return"";
    }  
  } 

}

