import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ImmunizationModel } from '../models/immunization.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http:HttpClient) { }

  getImmunizations(patientID:string,clientID:string,token:string){
    return new Promise<ImmunizationModel[]>((resolve)=>{ 
      const headers = new HttpHeaders()
      .set("Epic-Client-ID", clientID) 
      .set('Authorization', "Bearer "+ token) 
      .set('content-type', 'application/json')    
 
      this.http.get(environment.appochard_baseurl+`STU3/Immunization?patient=${patientID}`,
      { headers: headers }).subscribe((res:any)=>{ 
        const list = res.entry.length ? 
                    res.entry.filter((el)=>{
                      return el.resource.resourceType.toLowerCase() == "Immunization".toLowerCase();
                    }).map((el)=>{
                      return el.resource;
                    }) : [];
        resolve(list);
      });

    });
  }

  uploadFile(file: File){
    return new Promise<any>((resolve,reject)=>{
      const formData = new FormData();
      formData.append('file', file);
    
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
    
      this.http.post(environment.baseURL+`file/save`, formData, { headers }).subscribe(
        (response) => {resolve(response)},
        (error) => {reject(error.error);}
      );
    })
  }
}
