import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoadingController, PopoverController,AlertController, IonContent  } from '@ionic/angular';
import { TextUtilService } from 'src/app/core/utils/text.util.service';
import { ToastMessageService } from 'src/app/core/utils/toast-message.service';
import { AppointmentModel } from 'src/app/models/appointment.model';
import { Entry, MedicationStatementBundle } from 'src/app/models/medication-statement-bundle.model';
import { v4 as uuidv4 } from 'uuid';
import * as FHIR from "fhirclient";
import { PatientModel } from 'src/app/models/patient.model';
import { environment } from 'src/environments/environment';
import { Encounter } from 'src/app/models/encounter.model';
import { DocumentReference } from 'src/app/models/documentreference.model';
import { AllergyDisplayModel } from 'src/app/models/allergy.display.model';
import { ConditionModel } from 'src/app/models/condition.model';
import { AllergyModel } from 'src/app/models/allergy.model';
import { CustomHttpsService } from 'src/app/core/utils/custom-https.service';
import {  AppointmentFhirModel } from 'src/app/models/appointmentfhir.model'; 
import { Practitioner } from 'src/app/models/practitioner.model';
import { BinaryModel } from 'src/app/models/binary.model';
import { PatientFile } from 'src/app/models/patient-file.model';
import { IonPopOverListComponent } from 'src/app/shared/components/ion-pop-over-list/ion-pop-over-list.component';
import { TeleConfig } from 'src/app/models/teleconfig.model'; 
import { ProviderAppointment } from 'src/app/models/providerappointment.model';
import { PatientAppointment } from 'src/app/models/patientappoinment.model';
import { ProviderModel } from 'src/app/models/provider.model';
import { AppoinmentSearchService } from 'src/app/services/appoinment.search.service';
import { ProvidersService } from 'src/app/services/providers.service';
import { PatientService } from 'src/app/services/patient.service';
import { LocationService } from 'src/app/services/location.service';
import { LocationModel } from 'src/app/models/location.model';
import { TenantModel } from 'src/app/models/tenant.model';
import { TenantService } from 'src/app/services/tenant.service'; 
@Component({
  selector: 'app-meeting-doctor',
  templateUrl: './meeting-doctor.page.html',
  styleUrls: ['./meeting-doctor.page.scss'],
})
export class MeetingDoctorPage implements OnInit { 
  env= environment;
  clientID = environment.providerLaunchClientID;
  token=""; 
  appointmentNotes="";
  isMax= false;
  hpiNote:string=""; 
  chimeIframe = this.sanitizer.bypassSecurityTrustResourceUrl(environment.chime);
  appoinmentMsgStatus:string; 
  appointmentFhir:AppointmentFhirModel; 
  patient:PatientModel;  
  patientFiles:PatientFile[]=[];
  // location:LocationModel;
  practitioner:Practitioner;
  medicines :Entry[] =[] ;
  navLinks=[ { icon:"../../../assets/image/nav1.png", title:"Write a Prescription", }, { icon:"../../../assets/image/nav2.png", title:"Place an Order", }, { icon:"../../../assets/image/nav3.png", title:"Submit a Referral", }, { icon:"../../../assets/image/nav4.png", title:"Schedule a Visit", }, { icon:"../../../assets/image/nav5.png", title:"Send a Message", }, { icon:"../../../assets/image/nav6.png", title:"Back to Chart", } ]
  cTimers:any[]=[];  
  allergies:AllergyDisplayModel[] =[] ;
  conditions:ConditionModel[] = [];
  tenantModel:TenantModel={};
  // teleConfig: TeleConfig = {"Security":{"CaptureImageSecurity":true},"DocumentTypes":[{"Id":"1","Abbreviation":"OTHER","Title":"Other"},{"Id":"15","Abbreviation":"CLINICAL UNK","Title":"Clinical Unknown"},{"Id":"96001","Abbreviation":"PT IMAGE","Title":"Patient-Created Image"},{"Id":"96002","Abbreviation":"PT AUDIO","Title":"Patient-Created Audio"},{"Id":"96003","Abbreviation":"PT VIDEO","Title":"Patient-Created Video"}]};
  teleConfig: TeleConfig  ;
  
  isDesktop = false;
  isProduction: boolean;
  isDebug: boolean;
  code:string;
  
  isMeetingRun = true;
  isAvailableSoon = false;
  isHideTimer = false;
  isMeetingEnd = false;
  timezone: string;
  
  serverDate:any;
  isBothOnline = false;
  MeetingID = "";
  appointmentID="";
  devAppId = "";
  // prodAppId = ""; 
  providerDeviceID="";

  @ViewChild(IonContent) content: IonContent;
  isViewDetails = false;
  constructor(private http:HttpClient,
    private toast :ToastMessageService,
    private providersService:ProvidersService,
    private appointmentSearch :AppoinmentSearchService,
    public sanitizer: DomSanitizer, 
    private patientService:PatientService,
    private locationService:LocationService,
    private alertController:AlertController,
    private popoverCtrl:PopoverController,
    private tenantService:TenantService,
    private cusHttp:CustomHttpsService,
    private loadingController:LoadingController) { 
        
    const urlParams = new URLSearchParams(window.location.search);
    this.code = urlParams.get('code');
    this.isProduction = environment.production;
    this.isDebug = environment.debug;
  }

  async ngOnInit() {    
    this.tenantModel.isNlpDefaultOn = 1;
    this.tenantModel.isRecordDefaultOn = 1;
    const orgId = localStorage.getItem("providerSideOrgID");
    if(orgId){
      this.tenantModel.TenantID = orgId;
    }else{
      this.alert("No Org ID declared.");
      return;
    }

    this.timezone = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];  
    this.init();
    this.platformInit();  
  }
  
  platformInit(){
    setInterval(()=>{  
      // if(window.innerWidth < 1026){
      if(window.innerWidth < 991){
        //is mobile
        if(this.isDesktop==true){
          this.isViewDetails = false;
        }
        this.isDesktop = false; 
      }else{
        //is desktop
        if(this.isDesktop==false){
          this.isViewDetails = true;
        }
        this.isDesktop = true; 
      } 
    },1000)
  }
  

  loading:any;
  async init() {
    this.initDevice();
    this.loading = await this.loadingController.create({ message: "Please wait ...."  });
    this.loading.present(); 
    await this.initToken(); 
    
    try{

      this.tenantModel = await this.tenantService.getWithConfig(this.tenantModel.TenantID);
      await this.initPatient();   
      await this.initAppointment();  
      // await this.initPatientAppointment();
      await this.initPractitioner();
      // await this.initLocation();
  
      if(this.isProduction || !this.isDebug){
        // await this.initProdChime();
        await this.initDevChime();
        await this.initExternalConnection(); 
      }else{
        await this.initDevChime();
      }
  
      const isAccess = await this.initMiddleWare();  
      if(!isAccess){
        console.log("asdsadas====",isAccess);
        const alert = await this.alertController.create({
          message: '1 device per account only',
          backdropDismiss:false
        });
        await alert.present();
      } 
      
      this.getPatientFiles();
      this.initNote();
  
      this.initMedication();   
      this.initCondition(); 
      this.initAllergies();  
      this.initEncounter();
      
      if(this.appointmentFhir){  
        const ScheduledAt = this.appointmentFhir.start;  
        const ScheduledEndAt = this.appointmentFhir.end; 
        
        setInterval(()=>{ 
          const ctimer = TextUtilService.counDownTimer(ScheduledAt);  
          this.cTimers = ctimer;   
   
          const dateTimeDif = TextUtilService.getDateTimeDif(ScheduledAt);
          const dateTimeDifEnd = TextUtilService.getDateTimeDif(ScheduledEndAt);
          if(this.isBothOnline && !this.isMeetingEnd){
            this.isMeetingRun =true;
            this.isMeetingEnd = false;
            this.isAvailableSoon = false; 
            this.isHideTimer = false; 
            // Timer of the meeting
            this.cTimers = TextUtilService.counDownTimer(this.serverDate,true);  
            this.appoinmentMsgStatus = "Appointment Has Already Begun"; 
          }else{ 
            if((dateTimeDif[0].number <= -1 && dateTimeDif[1].number <= -1 && 
              dateTimeDifEnd[2].number >= 0 && dateTimeDif[2].number <= 0) && !this.isBothOnline  ){ 
              //  Time for meeting
              this.isHideTimer = true;  
              this.isMeetingRun =true;
              this.isMeetingEnd = false;
              this.isAvailableSoon = false; 
              // Timer of the meeting
              this.cTimers = TextUtilService.counDownTimer(ScheduledAt,true);     
              this.appoinmentMsgStatus = "Your visit will start soon"; 
            }else if(dateTimeDif[0].number <= 0 && dateTimeDif[1].number <= 0 && 
              dateTimeDifEnd[2].number < 0){
                //  Meeting End 
                this.isMeetingEnd = true;
                this.isHideTimer = true;
                this.isMeetingRun = false;
                this.isAvailableSoon = false;    
                this.appoinmentMsgStatus = "This session has ended";  
            }else if(parseInt(ctimer[0].number) > 0 || parseInt(ctimer[1].number) > 0 ||
              parseInt(ctimer[2].number) > 30){ 
                // Available soon 
              this.isAvailableSoon = true;
              this.isMeetingRun = false;
              this.isHideTimer = false;
              this.isMeetingEnd = false; 
              this.appoinmentMsgStatus = "Appointment Will Start In";
            }else{
              // 30 minutes before call
                // Prepare for the meeting
              this.isMeetingRun = true;
              this.isAvailableSoon = false;
              this.isHideTimer = false;
              this.isMeetingEnd = false;
              this.appoinmentMsgStatus = "Appointment Will Start In";
            }   
          }
        },1000);
         
      }else{
        this.noAppointment();
      }       
      setTimeout(() => {   
        this.loading.dismiss();
        // this.askDevicePermission();
      }, 6000); 

    }catch(e){
      this.alert(e.message);
      this.loading.dismiss();
    }
  }

  // initPatientAppointment() {
  //   return new Promise<any>(async (resolve)=>{ 
  //     const patientID  =this.patient.identifier.find((o)=>{ if(o.type){return o.type.text.toLowerCase() === "EPIC".toLowerCase()}}).value;
  //     // await this.patientService.getPatientAppointments(this.clientID,this.token,patientID); 
  //     const patientApts =  await this.patientService.getPatientAppointments(this.clientID,this.token,patientID);
  //     const currentPatientApts = patientApts.find((el)=>{return el.ContactIDs.find((er)=>{return er.ID == this.appointmentFhir.identifier[0].value;})  && el.VisitTypeIDs.find((er)=>{return er.ID.toLowerCase().includes("238")})  });
  //     console.log("currentPatientApts",currentPatientApts);
  //     if(!currentPatientApts){this.noAppointment();return;}
  //     this.aptNotes = currentPatientApts.AppointmentNotes;
  //     resolve({});
  //   })
  // }

  async initPractitioner() {
    const reference = this.appointmentFhir.participant.find((el)=>{return el.actor.reference.toLowerCase().includes("practitioner");}).actor.reference;
    
    console.log(`initPractitioner`,reference);
    this.practitioner = await this.providersService.getPractitioner(this.clientID,this.token,reference);
    console.log(`initPractitioner`,this.practitioner);
  }

  initAppointment() {
    return new Promise<any>(async(resolve)=>{ 
      const appsearchs = await this.appointmentSearch.get(this.clientID,this.token,this.patient.id);
      
      
      this.appointmentFhir = appsearchs.find((el)=>{   
        const ScheduledAt = el.start;  
        const ScheduledEndAt = el.end;     
  
        const dateTimeDif = TextUtilService.getDateTimeDif(ScheduledAt);
        const dateTimeDifEnd = TextUtilService.getDateTimeDif(ScheduledEndAt); 
  
        console.log("start",dateTimeDif);
        console.log("end",dateTimeDifEnd);
        if(dateTimeDif[0].number <= 0 && dateTimeDif[1].number <= 0 && 
          dateTimeDifEnd[2].number < 0){
          //  Meeting End  
        }else{
          return true;
        } 
      })  
      
      if(!this.appointmentFhir){
        this.noAppointment();
        return;
      } 

      if(this.appointmentFhir.start){ 
        // this.devAppId = this.patient.id+new Date(this.appointmentFhir.start).getTime() +"-"+this.tenantModel.TenantID;
        this.devAppId = this.appointmentFhir.id+"-"+this.tenantModel.TenantID;
      }else{
        this.noAppointment();
        return;
      } 
      this.appointmentNotes = this.appointmentFhir.comment;

      console.log("initAppointment",this.appointmentFhir)
      resolve({});
    })
  }
  
  initMiddleWare() {
    return new Promise<any>((resolve)=>{  
      this.cusHttp.post(`chimeApi/app-middleware?iam=provider&computerID=${this.providerDeviceID}&MeetingID=${this.MeetingID}`,{})
      .subscribe((el:any)=>{   
        resolve(el.isAccess);
      }); 
    })
  }
 
  askDevicePermission() {
    navigator.mediaDevices.getUserMedia({video: true, audio: true}).then( stream => { 
    }).catch( err => { 
      this.toast.presentToast("Camera and microphone requires to be permitted");
    });
  }

 
  async initToken() { 
    return new Promise<any>(async (resolve,reject)=>{ 
      
      FHIR.oauth2,
      FHIR.oauth2.ready()
      .then(async client => { 
        this.appointmentID = client.state.tokenResponse.appointment;
        // this.prodAppId = client.state.tokenResponse.appointment+ "-" +this.tenantModel.TenantID;
        this.token = client.state.tokenResponse.access_token; 

        if(!client.patient.id){ 
          try{ 

            const smartkey = sessionStorage.getItem("SMART_KEY").split('"')[1];
            const clientId= environment.providerLaunchClientID;  
            var headers = new Headers(); 
            var requestOptions:any = {
              method: 'GET',
              headers: headers,
              redirect: 'follow'
            };
        
            let app = JSON.parse(sessionStorage.getItem(smartkey));  
            if(!app.tokenResponse.access_token){
              var urlencoded = new URLSearchParams();
              urlencoded.append("code", this.code);
              urlencoded.append("grant_type", "authorization_code");
              urlencoded.append("redirect_uri", app.redirectUri);
              urlencoded.append("client_id", clientId);
      
              var requestOptions:any = {
                method: 'POST',
                headers: headers,
                body: urlencoded,
                redirect: 'follow'
              };
      
              fetch("https://vendorservices.epic.com/interconnect-amcurprd-oauth/oauth2/token", requestOptions)
              .then(response => response.json())
              .then(result => {
                var appID = result.appointment +"-"+this.tenantModel.TenantID; 
                app = Object.assign(app,{
                  tokenResponse:result
                }) 
                sessionStorage.setItem(smartkey,JSON.stringify(app));
                this.token = app.access_token;
                // this.prodAppId =  appID ;
                resolve({});
              })
              .catch(error =>{ reject({})});
            }else{
              resolve({})
            }
  
          }catch(err){
            console.log(err);
            this.onMyChartError();
            reject({}); 
          }
        }else{
          resolve({});
        }
      }).catch(()=>{
        this.onMyChartError();
      });
    })
  }  

  initDevChime() {
    console.log(`initDevChime`);
    return new Promise<any>(async (resolve)=>{  
      const body  = {
        Provider: {
          ID:this.practitioner.id
        },
        PatientID:this.patient.id,
        TenantID:this.tenantModel.TenantID,
        AppointmentID:this.devAppId,
        AppointmentName:this.appointmentFhir.serviceType[0].coding[0].display, 
        PatientName:this.patient.name[0].text, 
        ScheduledAt:this.appointmentFhir.start,
        ScheduledEndAt:this.appointmentFhir.end,
        FirstName:this.patient.name[0].given[0],
        LastName:this.patient.name[0].family,
        Gender:this.patient.gender,
        BirthDate:this.patient.birthDate
      }
      await new Promise<any>((resolve)=>{
        this.cusHttp.post(`chimeApi/createAppointment`,body)
        .subscribe((el:any)=>{resolve({})}); 
      })


      this.cusHttp.post(`chimeApi/checkMeeting?AppointmentID=${this.devAppId}&TenantID=${this.tenantModel.TenantID}`,{})
      .subscribe((el:any)=>{ 
        this.MeetingID = el.MeetingID; 
        this.devKeepMeAlive();
        resolve({});  
        this.chimeIframe = '?m='+el.MeetingID+"&n="+this.practitioner.name[0].text+"&appointmentID="+ this.devAppId+"&iam=provider"+"&isRecordDefaultOn="+this.tenantModel.isRecordDefaultOn+"&isNlpDefaultOn="+this.tenantModel.isNlpDefaultOn;
      });

    })
  }

  devKeepMeAlive() { 
    const inter  = setInterval(()=>{
      this.cusHttp.post(`chimeApi/keepmealive?AppointmentID=${this.devAppId}&MeetingID=${this.MeetingID}&iam=provider&computerID=${this.providerDeviceID}`,{})
      .subscribe((el:any)=>{ 
        console.log("keepmealive",el);
         
        if(el.isPatientOnline && el.isProviderOnline){
          this.isBothOnline = true;
          this.serverDate = el.serverDate;
          clearInterval(inter);
          this.devKeepMeAlive();
        }
        if(!el.success){
          if(el.isNeedRestart){ 
            setTimeout(async () => {
              clearInterval(inter);
              await this.initDevChime();
            }, 3000);
          } 
        }
      });
    },this.isBothOnline ? 1000*15 : 1000*5);
  }
  
  initNote() { 
    return new Promise<any>((resolve,reject)=>{  
      FHIR.oauth2.ready()
      .then(async client =>{
        const state = client.state.tokenResponse;
        const token = client.state.tokenResponse.access_token;
        
        for(const code of ['11506-3','10164-2','11488-4']){ 
          const headers = new HttpHeaders()
          .set("Epic-Client-ID", environment.providerLaunchClientID) 
          .set('Authorization', "Bearer "+ token)  
          .set('content-type', 'application/json')   
          
          const doc = await new Promise<DocumentReference>((resolve)=>{
            this.http.get(`${environment.appochard_baseurl}STU3/DocumentReference?encounter=${state.encounter}&type=${code}`,
            { headers: headers }).subscribe((doc:any)=>{    
              resolve(doc.entry);
            }); 
          })
          if(doc[0].resource.content){
            headers.set("accept","text/html")
            console.log(doc);
            await new Promise<any>((resolve)=>{
              this.http.get(doc[0].resource.content[0].attachment.url,
              { headers: headers,responseType:"text" }).subscribe((str)=>{    
                console.log(str);  
                resolve({}); 
                function removeTags(str) {
                  if ((str===null) || (str===''))
                  return false;
                  else
                  str = str.toString();
                  return str.replace( /(<([^>]+)>)/ig, '').replace(`&nbsp`, "").replace(/\r?\n|\r/g,'').replace(';',"");
                } 
                this.hpiNote = removeTags(str); 
                console.log(this.hpiNote);
              }); 
            })
          }
        } 
        resolve({});
      }) 
      .catch((err)=>{
        console.log(err); 
        reject({})}
      );
    })
  }
 
  initCondition() {
    console.log(`initCondition`);  
    FHIR.oauth2.ready()
    .then(client => client.request(`Condition?patient=${client.patient.id}`))
    .then((res:any)=>{
      for(const condition of res.entry){
        this.conditions.push(condition.resource);
      }
    })
    .catch(()=>{  
    });
  }

  
  initExternalConnection() {   
    return new Promise<any>((resolve,reject)=>{   
      FHIR.oauth2.ready()
      .then(async (client) => {
        
          const tokenResponse = client.state.tokenResponse; 
          
          // const ConferenceID= this.appointmentFhir.identifier[0].value;
          // const ExternalID= this.practitioner.identifier.find((o)=>{ return o.type.text.toLowerCase() === "EXTERNAL".toLowerCase()}).value;
          // let ExternalIDType= 1;
          // const VendorName= "Edera Video Visit";
          // const ConnectionStatus= 1;
          
           
          // Practitioner===========
          var ConferenceID= this.appointmentFhir.identifier[0].value;
          var ExternalID= this.practitioner.identifier.find((o)=>{ 
            if(o.type){
              return o.type.text.toLowerCase() === "EXTERNAL".toLowerCase()
            }}).value;
          var ExternalIDType= 1;
          var VendorName= "Edera Video Visit";
          var ConnectionStatus= 1;
           
           
          var token = client.state.tokenResponse.access_token; 
          var headers = new HttpHeaders()
          .set("Epic-Client-ID", environment.providerLaunchClientID) 
          .set('Authorization', "Bearer "+ token) 
          .set('content-type', 'application/json')   
   
          await new Promise<any>((resolve)=>{   
            this.http.get(`https://vendorservices.epic.com/interconnect-amcurprd-oauth/api/epic/2018/Telehealth/ContextLinking/SetExternalConnectionStatus?ConferenceID=${ConferenceID}&ExternalID=${ExternalID}&ExternalIDType=${ExternalIDType}&VendorName=${VendorName}&ConnectionStatus=${ConnectionStatus}`,
            { headers: headers }).subscribe((res:any)=>{
                console.log(res);  
                resolve({});
            })  
          }) 
          
          
          // this.http.get(`https://vendorservices.epic.com/interconnect-amcurprd-oauth/api/epic/2020/Telehealth/External/Connect`,
          // { headers: headers }).subscribe((res:any)=>{
          //     console.log(res);   
          // })  
          
          
          // Patient=========== 
          var ConferenceID= this.appointmentFhir.identifier[0].value; 
          var ExternalID= this.patient.identifier.find((o)=>{
            if(o.type){
              return o.type.text.toLowerCase() === "WPRINTERNAL".toLowerCase()}
            }).value; 
          var ExternalIDType= 2;
          var VendorName= "Edera Video Visit";
          var ConnectionStatus= 1;
           
          var token = client.state.tokenResponse.access_token; 
          var headers = new HttpHeaders()
          .set("Epic-Client-ID", environment.patientLaunchClientID) 
          .set('Authorization', "Bearer "+ token) 
          .set('content-type', 'application/json')   
   
          await new Promise<any>((resolve)=>{   
            this.http.get(`https://vendorservices.epic.com/interconnect-amcurprd-oauth/api/epic/2018/Telehealth/ContextLinking/SetExternalConnectionStatus?ConferenceID=${ConferenceID}&ExternalID=${ExternalID}&ExternalIDType=${ExternalIDType}&VendorName=${VendorName}&ConnectionStatus=${ConnectionStatus}`,
            { headers: headers }).subscribe((res:any)=>{
                console.log(res);  
                resolve({});
            })  
          })  

          this.http.get(`${environment.appochard_baseurl}STU3/Encounter/${tokenResponse.encounter}`,
          { headers: headers }).subscribe((res:any)=>{
              console.log(res);  
          })   

          await new Promise<any>((resolve)=>{   
            this.http.get(`https://vendorservices.epic.com/interconnect-amcurprd-oauth/api/epic/2018/Telehealth/External/TelemedicineConfiguration`,
            { headers: headers }).subscribe((res:TeleConfig)=>{
                console.log(res);  
                this.teleConfig = res;
                resolve({});
            })   
          }) 
          resolve({});
      }) 
      .catch(()=>{reject({})}); 
    });
  }

  initAllergies() {
    console.log(`initAllergies`); 
    return new Promise<any>((resolve,reject)=>{
      FHIR.oauth2.ready()
      .then(client => client.request(`AllergyIntolerance?patient=${client.patient.id}`))
      .then((res:any)=>{ 
        console.log(res.entry);
        resolve({});
        for(let entry of res.entry){
          const allergy:AllergyModel = entry.resource;
          console.log({
            title:allergy.code.text 
          })

          // if(allergy.reaction){
          //   const reactions = allergy.reaction.map((ek)=>{return ek.description}); 
          // }
          // console.log(`reactions`);
          // console.log(reactions);
          this.allergies.push({
            title:allergy.code.text,
            addedAt:allergy.onsetPeriod.start,
            reactions:allergy.reaction? allergy.reaction.map((ek)=>{return ek.description}).join(', '):''
          });
        }
        resolve({});
      })
      .catch(()=>{  
        reject({});
      });
    })
  }

  initEncounter(){ 
    return new Promise<any>((resolve,reject)=>{  
      FHIR.oauth2.ready()
      .then(client =>{
        const token = client.state.tokenResponse.access_token;
        const headers = new HttpHeaders()
        .set("Epic-Client-ID", environment.providerLaunchClientID) 
        .set('Authorization', "Bearer "+ token) 
        .set('content-type', 'application/json')    
        this.http.get(`${environment.appochard_baseurl}STU3/Encounter?patient=${client.patient.id}`,
        { headers: headers }).subscribe((enc:Encounter)=>{   
        });
        // this.http.get(`${environment.appochard_baseurl}STU3/Location/eq2u8wpRUgSPPRy-OCWNklE4IG5oSTcQVgaWjYvj0fSw3`,
        // { headers: headers }).subscribe((enc:Encounter)=>{   
        // });
        resolve({});
      }) 
      .catch((err)=>{
        console.log(err); 
        reject({})}
      );
    })
  }
  
  // async initProvider() {
  //   const MyChartAccountID=  this.patient.identifier.find((o)=>{ if(o.type){ return o.type.text.toLowerCase() === "WPRINTERNAL".toLowerCase() } }).value
  //   const VisitTypeID =  this.appointmentFhir.serviceType[0].coding[0].code;
  //   const patientID  =this.patient.identifier.find((o)=>{ if(o.type){return o.type.text.toLowerCase() === "EPIC".toLowerCase()}}).value;
  //   const provider = await this.providersService.get(this.clientID,this.token,patientID,MyChartAccountID,VisitTypeID)
  //   console.log("initProvider",provider);
  // } 

  initPatient() {
    return new Promise<any>((resolve,reject)=>{ 
      FHIR.oauth2, 
      FHIR.oauth2.ready()
      .then(client => client.request(`Patient/${client.patient.id}`))
      .then((el:PatientModel)=>{ 
        console.log(el);
        this.patient= el;
        resolve({});
      })
      .catch(async (err)=>{
        console.log(err); 
        const alert = await this.alertController.create({
          message: 'Session expired, please go back to HyperSpace.',
          backdropDismiss:false
        });
        reject({})}
      );
    })
  }
  
 
  // async initLocation() { 
  //   const reference = this.appointmentFhir.participant.find((el)=>{return el.actor.reference.toLowerCase().includes("location");}).actor.reference;
    
  //   console.log(`initLocation`,reference);
  //   this.location = await this.locationService.getLocation(this.clientID,this.token,reference);
  //   console.log(`initLocation`,this.location);
  // }
  
  initPastVisitNote() {
    return new Promise<any>((resolve,reject)=>{  
        FHIR.oauth2.ready()
        .then((client) => {
            console.log(client.state.tokenResponse.access_token); 
            const token = client.state.tokenResponse.access_token;
            const headers = new HttpHeaders()
            .set("Epic-Client-ID", environment.providerLaunchClientID) 
            .set('Authorization', "Bearer "+ token) 
            .set('content-type', 'application/json')     
            this.http.get(`${environment.appochard_baseurl}R4/DocumentReference?patient=${client.state.tokenResponse.patient}`,
            { headers: headers }).subscribe((res:any)=>{
                console.log(res); 
                if(res && res.entry){
                  res.entry.forEach((obj,index) => { 
                    this.http.get(obj.fullUrl,
                    { headers: headers }).subscribe(async (res:DocumentReference)=>{  
                      this.http.get(`${environment.appochard_baseurl}R4/${res.context.encounter[0].reference}`,
                      { headers: headers }).subscribe((enc:Encounter)=>{    
                        resolve({
                          name:enc.type[0].text, 
                          locationName:enc.location[0].location.display
                        });
                      });
                    });
                  })
                } 
            })
            resolve({}); 
        }) 
        .catch(()=>{reject({})}); 
    });
  }

  initMedication(){
    return new Promise<any>((resolve,reject)=>{  
      FHIR.oauth2.ready()
      .then(client => client.request(`MedicationStatement?Patient=${client.patient.id}`))
      .then((el:MedicationStatementBundle)=>{ 
        const entries:any = el.entry.filter((o:Entry)=>{
          return o.resource.resourceType === "MedicationStatement";
        });
        console.log(entries);
        this.medicines = entries; 
        resolve({});
      })
      .catch((err)=>{
        console.log(err); 
        reject({})}
      );
    })
  } 
  
 

  saveNote(){  
    return new Promise<any>((resolve,reject)=>{    
      FHIR.oauth2.ready()
      .then(async (client) => {
        console.log("saveNote",client.state.tokenResponse);
        const tokenResponse = client.state.tokenResponse;
        const token = client.state.tokenResponse.access_token; 
        if(this.hpiNote == ""){
          this.hpiNote = " ";
        }
        const headers = new HttpHeaders()
        .set("Epic-Client-ID", environment.providerLaunchClientID) 
        .set('Authorization', "Bearer "+ token) 
        .set('content-type', 'application/json');  
        const body ={
          "type": {
            "coding": [
              {
                "system": "http://loinc.org",
                "code": "11506-3"
              },
              {
                "system": "http://loinc.org",
                "code": "10164-2",
                "display":"Progress Note"
              },{
                "system": "http://loinc.org",
                "code": "11488-4",
                "display": "Consultation Note"
              }
            ]
          },
          "subject": {
            "reference": tokenResponse.patient
          },
          "content": [
            {
              "attachment": {
                "contentType": "text/plain", 
                "data":btoa(this.hpiNote)
              }
            }
          ],
          "context": {
            "encounter": {
              "reference": tokenResponse.encounter
            }
          }
        } 
        
        await new Promise<any>((resolve)=>{  
          this.http.get(`${environment.appochard_baseurl}STU3/Encounter/${tokenResponse.encounter}`,
          { headers: headers }).subscribe((res:any)=>{
              console.log(res);  
              resolve({});
          })   
        }) 

        await new Promise<any>((resolve)=>{
          this.http.post(environment.appochard_baseurl+"STU3/DocumentReference",
          body, { headers: headers }).subscribe((res:any)=>{
              console.log(res); 
              // this.appointments = res.Appointments;
              resolve({});
          })
        }) 
           
        resolve({});
      }) 
      .catch(()=>{reject({})}); 
    });
  }

  async onRefreshFiles(){  
    if(this.isProduction || this.isDebug){
      const loading = await this.loadingController.create({ message: "Please wait ...."  });
      await loading.present();
      await this.getPatientFiles();
      await loading.dismiss();
    }
  }

  getPatientFiles() {
    return new Promise<any>(resolve=>{ 
      FHIR.oauth2.ready()
      .then(async client =>{   
        const headers = new HttpHeaders() 
        .set('content-type', 'application/json')   
        
        this.http.get(`${environment.baseURL}patientFile/listbyappointmentid?appointmentID=${this.devAppId}`,
        { headers: headers }).subscribe(async (patientFiles:PatientFile[])=>{    
          this.patientFiles = patientFiles;  
          resolve({});
        }); 
        // if(this.isDebug){ 
        //   const headers = new HttpHeaders() 
        //   .set('content-type', 'application/json')   
          
        //   this.http.get(`${environment.baseURL}patientFile/listbyappointmentid?appointmentID=${this.devAppId}`,
        //   { headers: headers }).subscribe(async (patientFiles:PatientFile[])=>{    
        //     this.patientFiles = patientFiles;  
        //     resolve({});
        //   }); 
        // }else{ 
        //   const tokenResponse = client.state.tokenResponse;
        //   const headers = new HttpHeaders() 
        //   .set('content-type', 'application/json')   
          
        //   this.http.get(`${environment.baseURL}patientFile/listbyappointmentid?appointmentID=${this.prodAppId}`,
        //   { headers: headers }).subscribe(async (patientFiles:PatientFile[])=>{    
        //     this.patientFiles = patientFiles;  
        //     resolve({});
        //   }); 
        // }
      }) 
      .catch((err)=>{
        console.log(err);  
      });
    });
  }
  
  onOpenFile(URL:string){
    window.open(URL,"_blank");
  }
  
  saveMedia(file:File,documentType,patientFilesID:string) {   
    return new Promise<any>((resolve,reject)=>{  
      FHIR.oauth2.ready()
      .then(async (client) => {
          console.log(client.state.tokenResponse.access_token); 
          const token = client.state.tokenResponse.access_token;

          const loading = await this.loadingController.create({ message: "Please wait ...."  });
          await loading.present();
          await new Promise<any>((resolve)=>{
            const headers = new HttpHeaders()
            .set("Epic-Client-ID", environment.providerLaunchClientID) 
            .set('Authorization', "Bearer "+ token) 
            .set('content-type', file.type)    
            
            this.http.post(`https://vendorservices.epic.com/interconnect-amcurprd-oauth/api/epic/2018/Telemedicine/External/SaveMedia?DocumentType=${documentType}&Description=PatientFile`,
            file,{ headers: headers }).subscribe((res:any)=>{ 
                resolve({})
            })
          })
          await new Promise<any>(async (resolve)=>{
            const body ={
              patientFilesID:patientFilesID,
              status:"approved"
            } 
            
            this.http.put(`${environment.baseURL}patientFile/changestatus`,
            body).subscribe(async (res:any)=>{
              await this.getPatientFiles();
              resolve({});
            })  
          });

          await loading.dismiss(); 
          this.toast.presentToast("Uploaded");
          resolve({}); 
      }) 
      .catch(()=>{reject({})}); 
    });
  }
 
  isSaving = false;
  async onSavePatientFile(ev:any,URL:string,fileName:string,patientFilesID:string){ 
    if(this.isSaving){
      return;
    }
    this.isSaving= true;
    const file =  await this.getFileFromUrl(URL,fileName);
    console.log(file);
    
    // console.log(URL);
    const popover = await this.popoverCtrl.create({
      component: IonPopOverListComponent,  
      translucent: true,
      event:ev,
      componentProps:{
        title:"Document type",
        buttons:this.teleConfig.DocumentTypes.map((el)=>{return el.Title;})
      }
    });
    await popover.present();
    popover.onDidDismiss().then(async (el)=>{
      if(el.data){       
        const arr_i = el.data.index;
        const documentType = this.teleConfig.DocumentTypes[arr_i].Id;   
        await this.saveMedia(file,documentType,patientFilesID);
      }
       this.isSaving = false;
    });  
  }

  async onDelete(patientFilesID:string){
    const alert = await this.alertController.create({ 
      header: 'Delete!',
      message: 'Do you want to permanentely <strong>delete<strong>?',
      buttons: [
        {
          text: 'No',
          role: 'no',
          cssClass: 'secondary',
          handler: (blah) => { 
          }
        }, {
          text: 'Yes',
          handler: async () => { 
            const loading = await this.loadingController.create({ message: "Please wait ...."  });
            await loading.present();
            this.http.delete(`${environment.baseURL}patientFile?patientFilesID=${patientFilesID}`).subscribe(async (res:any)=>{
              await this.getPatientFiles();
              await loading.dismiss();
              this.toast.presentToast("Deleted");
            })  
          }
        }
      ]
    }); 
    await alert.present();
  }
 

  async getFileFromUrl(url, name, defaultType = 'image/jpeg'){
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: response.headers.get('content-type') || defaultType,
    });
  }
  
  initDevice() {
    this.providerDeviceID= localStorage.getItem("provider-uid");
    if(!this.providerDeviceID){
      this.providerDeviceID = uuidv4();
      localStorage.setItem("provider-uid",this.providerDeviceID);
    } 
  } 

  // getProviderAppointments(appointment){ 
    
  //   console.log('getProviderAppointments',appointment);
  //   return new Promise<ProviderAppointment[]>((resolve,reject)=>{   
  //     FHIR.oauth2.ready()
  //     .then(async (client) => { 
  //         const tokenResponse = client.state.tokenResponse;   
  //         const token = tokenResponse.access_token;
  //         const headers = new HttpHeaders()
  //         .set("Epic-Client-ID", environment.patientLaunchClientID) 
  //         .set('Authorization', "Bearer "+ token) 
  //         .set('content-type', 'application/json')   
  //         const arrdate = new Date(appointment.ArrivalTime);
  //         let enddate = new Date(appointment.ArrivalTime);
  //         enddate.setDate(enddate.getDate()+2);
  //         const body ={
  //           "UserID": "1",
  //           "UserIDType": "External",
  //           "StartDate": arrdate.toLocaleDateString(),
  //           "EndDate": enddate.toLocaleDateString(),
  //           "CombineDepartments": "true",
  //           "ResourceType": "",
  //           "Specialty": "",
  //           "ExtraItems": [ ],
  //           "Providers": [
  //             { 
  //               "ID": appointment.ProviderDepartments[0].Provider.IDs.find(el=>{return el.Type.toLowerCase() === "external"}).ID,
  //               "IDType": "external",
  //               "DepartmentID": appointment.ProviderDepartments[0].Department.IDs.find(el=>{return el.Type.toLowerCase() === "external"}).ID,
  //               "DepartmentIDType": "external"
  //             }
  //           ],
  //           "Departments": [ ],
  //           "Subgroups": [ ],
  //           "ExtraExtensions": [ ]
  //         }; 
  //         console.log('getProviderAppointments',body);
  //         this.http.post(`https://vendorservices.epic.com/interconnect-amcurprd-oauth/api/epic/2013/Scheduling/Provider/GetProviderAppointments/Scheduling/Provider/Appointments`,
  //         body,{ headers: headers }).subscribe((res:any)=>{
  //             console.log('getProviderAppointments',res);  
  //             const providerAppointments :ProviderAppointment[]= (<ProviderAppointment[]> res.Appointments); 
  //             resolve(providerAppointments);
  //         })   
  //     }) 
  //     .catch(()=>{reject({})}); 
  //   });
    
  // } 






  async onMyChartError(){ 
    this.loading.dismiss();
    const alert = await this.alertController.create({
      message: 'Session expired, please go back to HyperSpace.',
      backdropDismiss:false
    });
  
    await alert.present(); 
  }

  async alert(message){  
    if(message=='' || !message){message = 'Session expired, please go back to HyperSpace.'}
    const alert = await this.alertController.create({
      message: message,
      backdropDismiss:false
    }); 
    await alert.present(); 
  } 

  async noAppointment() {
    const alert = await this.alertController.create({
      message: 'Have no appointment scheduled.',
      backdropDismiss:false
    });
    await alert.present();  
    await this.loading.dismiss();
  }

  
  onToggleViewDetails(){
    this.isViewDetails = !this.isViewDetails;
    if(this.isViewDetails){   
      this.content.scrollToTop(400);
    }
  }
  
}


