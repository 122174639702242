import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core'; 
import { environment } from 'src/environments/environment';
import { PractitionerRole } from '../models/practioner-role.model';
import { Practitioner, PractitionerWithRole } from '../models/practitioner.model';
import { ProviderModel } from '../models/provider.model';
import { BlobUtil } from '../utils/blob.util';

@Injectable({
  providedIn: 'root'
})
export class ProvidersService {

  constructor(private http:HttpClient) { }

  // get(clientID,token,PatientID,MyChartAccountID,VisitTypeID){ 
  //   return new Promise<ProviderModel>((resolve)=>{   
  //     const headers = new HttpHeaders()
  //     .set("Epic-Client-ID", clientID) 
  //     .set('Authorization', "Bearer "+ token) 
  //     .set('content-type', 'application/json')   
  //     const arrdate = new Date();
  //     let enddate = new Date();
  //     enddate.setDate(enddate.getDate()+2);
  //     const body ={
  //       "PatientID": PatientID,
  //       "PatientIDType": "EPI",
  //       "Centers": [ "" ],
  //       "MyChartAccountID": MyChartAccountID,
  //       "MyChartAccountIDType": "External",
  //       "VisitType": {
  //         "ID": VisitTypeID,
  //         "Type": "External"
  //       }
  //     };  
  //     this.http.post(`https://vendorservices.epic.com/interconnect-amcurprd-oauth/api/epic/2019/PatientAccess/External/GetProviders/Epic/Patient/Scheduling2019/GetProviders`,
  //     body,{ headers: headers }).subscribe((res:any)=>{
  //         // console.log('getProviderAppointments',res);  
  //         // const providerAppointments :ProviderAppointment[]= (<ProviderAppointment[]> res.Appointments); 
  //         // resolve(providerAppointments);
  //     })   

  //   })
  // }

  getPractitioner(clientID,token,reference){ 
    return new Promise<Practitioner>((resolve)=>{   
      const headers = new HttpHeaders()
      .set("Epic-Client-ID", clientID) 
      .set('Authorization', "Bearer "+ token) 
      .set('content-type', 'application/json') ;
      this.http.get(reference,
      { headers: headers }).subscribe(async(res:Practitioner)=>{
        if(res.photo){ 
          let photoURL = res.photo[0].url;
          let binaryID = photoURL.substring(photoURL.lastIndexOf('/') + 1);
          res.photoConverted = !photoURL.toLowerCase().includes("api/am/imageFileData".toLowerCase())  ? await this.getPractionerPhoto(clientID,token,binaryID) : photoURL ;
        }
        resolve(res);
      })   

    })
  }

  getPractitionerRoleSearch(clientID,token,practitionerID){ 
    return new Promise<any>((resolve)=>{   
      const headers = new HttpHeaders()
      .set("Epic-Client-ID", clientID) 
      .set('Authorization', "Bearer "+ token) 
      .set('content-type', 'application/json') ;
      this.http.get(environment.appochard_baseurl+`STU3/PractitionerRole?practitioner=${practitionerID}`,
      { headers: headers }).subscribe((res:any)=>{
        resolve(res);
      })    
    })
  }

  getPractitionerWithRole(clientID,token,reference){ 
    return new Promise<PractitionerWithRole>(async (resolve)=>{   
       const practitioner = await this.getPractitioner(clientID,token,reference);
       const role = (await this.getPractitionerRoleSearch(clientID,token,practitioner.id)).entry.find((el)=>{return el.resource.resourceType == "PractitionerRole";}).resource; 
       resolve({practitioner:practitioner,role:role});
    })
  }

  getPractionerPhoto(clientID,token,binaryID){
    return new Promise<string>((resolve)=>{   
      const headers = new HttpHeaders()
      .set("Epic-Client-ID", clientID) 
      .set('Authorization', "Bearer "+ token) 
      .set('Accept', 'image/jpeg') ; 
      this.http.get(environment.appochard_baseurl+`R4/Binary/${binaryID}`,
      { headers: headers,responseType:"blob" }).subscribe(async (res:any)=>{
        res = await BlobUtil.getDataFromBlob(res);
        console.log("getPractionerPhoto",res);
        resolve(res);
      })  
    })
  }

  // getProviderAppointments(clientID,token,PatientID,MyChartAccountID,VisitTypeID){ 
  //   return new Promise<ProviderModel>((resolve)=>{   
  //     const headers = new HttpHeaders()
  //     .set("Epic-Client-ID", clientID) 
  //     .set('Authorization', "Bearer "+ token) 
  //     .set('content-type', 'application/json')   
  //     const arrdate = new Date();
  //     let enddate = new Date();
  //     enddate.setDate(enddate.getDate()+2);
  //     const body ={
  //       "UserID": "1",
  //       "UserIDType": "External",
  //       "StartDate": "t-7",
  //       "EndDate": "t",
  //       "CombineDepartments": "true",
  //       "ResourceType": "",
  //       "Specialty": "",
  //       "ExtraItems": [
          
  //       ],
  //       "Providers": [
  //         {
  //           "ID": "E1000",
  //           "IDType": "external",
  //           "DepartmentID": "",
  //           "DepartmentIDType": ""
  //         }
  //       ],
  //       "Departments": [ ],
  //       "Subgroups": [ ],
  //       "ExtraExtensions": [ ]
  //     };  
  //     this.http.post(`https://vendorservices.epic.com/interconnect-amcurprd-oauth/api/epic/2013/Scheduling/Provider/GetProviderAppointments/Scheduling/Provider/Appointments`,
  //     body,{ headers: headers }).subscribe((res:any)=>{
  //         // console.log('getProviderAppointments',res);  
  //         // const providerAppointments :ProviderAppointment[]= (<ProviderAppointment[]> res.Appointments); 
  //         // resolve(providerAppointments);
  //     })   

  //   })
  // }
}
